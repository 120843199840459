import React from "react"
import styled from "styled-components"
import Inner from "../../zzz/layout/pageInner/Inner"
import Logo from "../../zzz/atoms/logo"
import { LinkWrapper as Link } from "../../utils/linkWrapper"
import { graphql, useStaticQuery } from "gatsby"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Layout = ({ children }) => {
  const graphqlData = useStaticQuery(graphql`
    {
      localWpGraphQlTmwGeneralSettings {
        tmw_settings {
          contactDetails {
            genericPhone
          }
        }
      }
    }
  `)
  const {
    genericPhone,
  } = graphqlData.localWpGraphQlTmwGeneralSettings.tmw_settings.contactDetails

  return (
    <Container>
      <Header>
        <Inner>
          <Link to="/" className="logo-link">
            <Logo />
          </Link>
          <Text>
            Need help? Call{" "}
            <a href={`tel:${genericPhone.replace(/ /gi, "")}`}>
              {genericPhone}
            </a>
          </Text>
        </Inner>
      </Header>
      {children}
    </Container>
  )
}

// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Container = styled.div`
  min-height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  /* overflow: hidden; */
  p {
    font-size: 15px;
  }
  label {
    font-size: 15px;
    padding-bottom: 2px;
  }
`
const Text = styled.div`
  color: #b6c9e3;
  font-size: 15px;
  a {
    color: #b6c9e3;
    text-decoration: underline;
  }
`
const Header = styled.div`
  background-color: #223247;
  color: #b6c9e3;
  padding: 20px 0;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo-link {
      height: 40px;
      display: block;
      svg {
        height: 100%;
      }
    }
  }
`

export default Layout
